<template>
    <div>
        <v-container id="regular-tables" fluid tag="section">
            <v-simple-table :dark="$store.state.isDarkMode">
                <thead class="table-heading" v-if="!meal.mealState.loading">
                    <tr>
                        <th v-for="(name, i) in tableOptions.tableHeaders" :key="i">
                            {{ $t(name) }}
                        </th>
                    </tr>
                </thead>
                <div class="table__spinnerWrapper" v-if="meal.mealState.loading">
                    <app-spinner></app-spinner>
                </div>
                <v-fade-transition mode="out-in" :group="true" tag="tbody">
                    <tr v-for="(item, idx) in tableOptions.tableData" :key="idx" v-show="!meal.mealState.loading">
                        <td>
                            <!-- <img :src="item.image" alt=""> -->
                            <div class="d-flex flex-column justify-space-between align-center">
                                <v-img style="margin-top: 5px;margin-bottom: 5px;" :aspect-ratio="16 / 9" :width="100"
                                    :src="getImgUrl(item.image)"></v-img>
                            </div>
                        </td>
                        <td>
                            <span>{{ item.id }}</span>
                        </td>
                        <td>
                            {{ item.name }}
                        </td>
                        <td>
                            {{ item.description }}
                        </td>
                        <td>
                            {{ item.status }}
                        </td>
                        <td>
                            <span v-for="(day, i) in  item.days" :key="i">{{ day }},</span>
                        </td>
                        <td>
                            {{ item.price }}
                        </td>
                        <td v-if="!meal.mealState.loading" style="height: 90px;" class="d-flex justify-center">
                            <edit-meal :item="item"></edit-meal>
                            <delete-meal :item="item"></delete-meal>
                        </td>
                    </tr>
                </v-fade-transition>
            </v-simple-table>
            <div class=" d-flex justify-end mt-5" v-if="!meal.mealState.loading">
                <app-pagination :totalPages="tableOptions.tableMeta.total" :page="tableOptions.tableMeta.page"
                    @PaginationValue="fetchNewPaginated($event)"></app-pagination>
            </div>
        </v-container>
    </div>
</template>
  
<script>
import spinner from "./spinner.vue";
import pagination from "./pagination.vue";
import editMeal from "../../views/dashboard/meal/editMeal.vue";
import deleteMeal from "../../views/dashboard/meal/deleteMeal.vue";
import { meal } from '../../store/auth'
export default {
    components: {
        appSpinner: spinner,
        appPagination: pagination,
        editMeal,
        deleteMeal
    },
    data() {
        return {
            meal,
            deleteDialog: false,
        };
    },

    props: {
        serverRoute: String,
        enableDelete: {
            type: Boolean,
            default: true,
        },
        flaggedItems: {
            type: Array,
            default() {
                return [];
            },
        },
        displaySettings: {
            type: Boolean,
            default: true,
        },
        tableOptions: {
            type: Object,
            default: () => {
                return {};
            },
        },
    },
    // computed: {
    //   ...mapGetters({
    //     tableOptions: "tablesStore/getTableOptions",
    //   }),
    // },
    methods: {
        castItems(item) {
            let copyItem = { ...item };
            this.flaggedItems.forEach((el) => {
                delete copyItem[el];
            });
            return copyItem;
        },
        accessData(row, index) {
            return this.fetchAttributes(
                row,
                this.tableOptions.accessTableData[index]
            );
        },
        fetchAttributes(row, nestedKey) {
            let value = row;
            for (let key in nestedKey) {
                if (value[nestedKey[key]]) {
                    value = value[nestedKey[key]];
                } else {
                    return;
                }
            }
            return value.toLocaleString();
        },
        fetchNewPaginated(paginationValue) {
            this.$emit("re-fetch-paginated-data", paginationValue);
        },
        reFetchData() {
            this.$emit("re-fetch-data");
        },
        getImgUrl(item) {
            let domain = "http://127.0.0.1:8080/"
            let item2 = item.replace("public", "storage")
            return domain + item2
        }
    },
};
</script>
  
<style >

</style>